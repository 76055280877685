/* Instance HTML from the API. */
.instance-description a {
  @apply underline;
}

.instance-description b,
.instance-description strong {
  @apply font-bold;
}

.instance-description i,
.instance-description em {
  @apply italic;
}
